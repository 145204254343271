<template>
  <div class="site-aside-container">
    <!-- logo -->
    <div class="logo">
      <div class="logo-area" @click="$router.push({ path: '/dashboard/home' })">
        <img
          @mouseenter="logoIsHover = true"
          @mouseleave="logoIsHover = false"
          :src="
            require(`@/assets/img/05-Panda/nihaokids-logo` +
              (logoIsHover == true ? '-hover' : '') +
              `.svg`)
          "
          title="nihaoKids"
        />
      </div>
    </div>

    <!-- 家庭成员 -->
    <div class="family-merber">
      <div class="family-merber-area">
        <div
          class="family-merber-single"
          v-for="(item, index) in kidsMember"
          :key="index"
          :class="{ activeKid: curretnKidIndex === index }"
        >
          <div
            class="family-merber-avatar"
            @click="chooseWhichKid(item, index)"
          >
            <img :src="item.photo" alt="" />
          </div>
          <div class="family-merber-name font-menu-small">
            {{ item.firstName }}
          </div>
        </div>

        <!-- <div class="family-merber-add">
          <img
            src="@/assets/img/19-Dashboard/icon-add-blue.svg"
            alt=""
            @click="
              $router.push({ path: '/dashboard/profile', query: { id: '1' } })
            "
          />
        </div> -->
      </div>
    </div>

    <!-- 菜单 -->
    <div class="menu">
      <div class="menu-list font-menu-large">
        <div class="menu-item" v-for="item in menuList" :key="item.id">
          <router-link :to="item.url">{{ item.name }}</router-link>
        </div>
      </div>

      <!-- 网络测试 -->
      <div class="test-net" @click="openCheckMask">
        <img src="@/assets/img/19-Dashboard/icon-mic-small.svg" alt="" />
        <div>DEVICE CHECK</div>
      </div>
    </div>
    <div class="device-check-mask" v-if="isOpenDeviceCheck">
      <div class="mask-content scaleToAppear">
        <DeviceCheck @close="closeCheckMask" />
      </div>
    </div>
  </div>
</template>
<script>
import DeviceCheck from "./DeviceCheck";

export default {
  components: {
    DeviceCheck,
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          name: "Home",
          url: "/dashboard/home",
        },
        {
          id: 2,
          name: "Book a Class",
          url: "/dashboard/bookclass",
        },
        // {
        //   id: 3,
        //   name: "Schedule",
        //   url: "/dashboard/schedule",
        // },
        {
          id: 4,
          name: "Review",
          url: "/dashboard/review",
        },
        // {
        //   id: 5,
        //   name: "Refer a Friend",
        //   url: "/dashboard/referafriend",
        // },
      ],
      logoIsHover: false,
      kidsMember: [],
      userInfo: {},
      curretnKidIndex: 0,
      isOpenDeviceCheck: false,
    };
  },
  created() {
    // console.log(this.$store.state.dashboard.isKid);
    if (this.$store.state.dashboard.isKid) {
      const { userSetting } = JSON.parse(localStorage.getItem("userData"));
      this.kidsMember.push(userSetting);
    } else {
      const { familyMember } = JSON.parse(localStorage.getItem("userData"));
      const { userSetting } = JSON.parse(localStorage.getItem("userData"));
      this.userId = userSetting.userId;
      // console.log(kidsMember);
      this.kidsMember =
        familyMember.length > 0
          ? familyMember.filter((item) => item.isKids)
          : null;
    }
  },
  mounted() {
    // console.log(this.kidsMember && !this.$store.state.dashboard.kidUserId);
    if (this.kidsMember && !this.$store.state.dashboard.kidUserId) {
      this.$store.commit("setKidUserId", this.kidsMember[0].id);
      this.$store.commit("setKidUserIndex", 0);

      this.curretnKidIndex = 0;
    } else {
      this.curretnKidIndex = this.$store.state.dashboard.kidUserIndex;
      // console.log(this.$store.state.dashboard.kidUserIndex);
    }
  },
  computed: {
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
  },
  methods: {
    chooseWhichKid(item, index) {
      this.curretnKidIndex = index;
      this.$store.commit("setKidUserId", item.id);
      this.$store.commit("setKidUserIndex", index);
    },
    openCheckMask() {
      this.isOpenDeviceCheck = true;
    },
    closeCheckMask() {
      this.isOpenDeviceCheck = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.site-aside-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: #224e96;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;

  // min-width: 150px;
  // border: 1px solid #000;
  .logo {
    width: 100%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-area {
      width: 45%;

      img {
        width: 100%;
      }
    }
  }

  .family-merber {
    width: 82%;
    background-color: #f4d737;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    .family-merber-area {
      // width: 100%;
      height: 100%;
      // padding-left: 28%;
      // padding-right: 7%;
      padding: 8% 7% 8% 28%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: flex-start;

      .family-merber-single {
        flex: 1;
        width: 23%;
        margin: 0 5%;
        text-align: center;

        &:not(.activeKid) {
          opacity: 0.7;
        }

        img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          object-fit: cover;
          cursor: pointer;
        }

        .family-merber-name {
          // max-width: 40px;
          // font-size: .7rem;
          // line-height: 1.3;
          text-align: center;
          overflow: hidden;
          color: #224e96;
          font-weight: 600;
        }
      }

      .family-merber-add {
        height: 100%;
        flex: 1;
        margin: 0 5%;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          max-width: 70px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .menu {
    width: 100%;
    margin-top: 17%;

    .menu-list {
      padding-left: 23%;

      .menu-item {
        margin-bottom: 4%;
        font-weight: 600;

        .router-link-active {
          color: #f4d737;
        }

        a {
          color: #fff;

          &:hover {
            color: #f4d737;
            transition: linear 0.5s;
          }
        }
      }

      //  :last-child {
      //     margin-top: 11%;
      // }
    }

    .test-net {
      margin-left: 23%;
      position: absolute;
      bottom: 6%;
      background: #fff;
      font-weight: 600;
      color: #224e96;
      padding: 10px 15px;
      border-radius: 30px;
      font-size: 0.8em;
      cursor: pointer;
      display: flex;
      // flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      &:hover {
        transform: translateY(-3px);
      }

      img {
        margin-right: 5px;
      }

      div {
        line-height: 0.8em;
      }
    }
  }

  .device-check-mask {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 9999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);

    .mask-content {
      width: 28%;
      height: 72%;
    }
  }
}

.logo-area {
  cursor: pointer;
}
</style>
