<template>
  <div class="layout-container">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="main">
      <slot name="main"></slot>
    </div>
    <!-- <div class="right">
      <slot name="right"></slot>
    </div> -->
  </div>
</template>

<script>
export default {
  name:"Layout",

};
</script>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
  height: 100vh;
  display: flex;
  // justify-content: left;
  .left {
    // flex: 0 0 auto;
    flex: 1;
    height: 100%;
    overflow: hidden;
  }
  .main {
    // flex: 1 1 auto;
    flex: 4;
    height: 100%;
    overflow: hidden;
  }
}
</style>