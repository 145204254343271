<template>
  <div class="device-check-container">
    <!-- option title -->
    <div class="optionTitle">
      <!-- close -->
      <img
        class="optionClose"
        :src="require('@/assets/img/08-Device-check/add_back_blue.svg')"
        @click="close()"
      />
    </div>
    <!-- switch step -->
    <!--  v-show="stepCode !== 4" -->
    <div class="switchStep" v-show="stepCode !== 4 || allSuccess">
      <!-- line -->
      <!-- <div class="line" :style="[stepIsSemiTransparent2 ? {width: `0px`} : stepIsSemiTransparent3 ? {width: `85px`} : {width: `170px`}]"></div> -->
      <!-- audio -->
      <img
        class="stepIcon audio"
        :class="{ status: audioCode, noStatus: !audioCode && stepCode == 1,
        scaleToAppear : showStep1,
        isInvisible: !showStep1,
        stepIsSemiTransparent: stepIsSemiTransparent1,
        checkmarkAnim :stepCode >= 2,
        }"
        :src="img_step1_title"
      />
      <!-- camera -->
      <img
        class="stepIcon camera"
        :class="{
          status: cameraCode == 1 || cameraCode == 2,
          noStatus: (cameraCode !== 1 || cameraCode !== 2) && stepCode == 2,
          scaleToAppear : showStep2,
          isInvisible: !showStep2,
          stepIsSemiTransparent: stepIsSemiTransparent2,
          checkmarkAnim :stepCode >= 3,
        }"
        :src="img_step2_title"
      />
      <!-- microphone -->
      <img
        class="stepIcon microphone"
        :class="{
          status: microphoneCode == 1 || microphoneCode == 2,
          noStatus:
            (microphoneCode !== 1 || microphoneCode !== 2) && stepCode == 3,
            scaleToAppear : showStep3,
            isInvisible: !showStep3,
            stepIsSemiTransparent: stepIsSemiTransparent3,
            checkmarkAnim :stepCode >= 4,
        }"
        :src="img_step3_title"
      />
    </div>
    <!-- 初始状态 -->
    <div class="contentStep stepCode_0" v-show="stepCode == 0">
      <img
        class="computer"
        :src="require('@/assets/img/08-Device-check/image-laptop.svg')"
      />
      <div>
        <p class="tips-title">TEST YOUR DEVICE BEFORE CLASS</p>
        <p class="tips-detail">Speaker, camera and microphone check</p>
      </div>
      <div class="customButton start" @click="startCheck()">START</div>
    </div>
    <!-- check 扬声器状态 -->
    <div class="contentStep stepCode_1" v-show="stepCode == 1">
      <!-- 音频动图 -->
      <AnimateAudioLine
        class="audioLine"
        ref="stepCode_1_audioLine"
        :audiourl="require('@/assets/img/08-Device-check/test-audio.wav')"
      ></AnimateAudioLine>
      <!-- describe -->
      <p class="des tips-title">DID YOU HEAR THE MUSIC</p>
      <!-- buttom group -->
      <div class="buttonGroup font_three">
        <div class="customButton no" @click="stepCode_1_no()">NO</div>
        <div class="customButton yes" @click="stepCode_1_yes()">YES</div>
      </div>
    </div>
    <!-- check 摄像头 -->
    <div class="contentStep stepCode_2" v-show="stepCode == 2">
      <!-- cameraCode 3 -->
      <div class="cameraCode3" v-show="cameraCode == 3">
        <div class="content">
          <!-- img group -->
          <div class="imgGroup">
            <img
              :src="
                require('@/assets/img/08-Device-check/image-camera-access.svg')
              "
            />
          </div>
        </div>
        <!-- des -->
        <div v-if="nocamera">
          <p class="tips-title">There was a problem setting up your camera</p>
        </div>
        <div v-else>
          <p class="tips-detail">Allow the access to your camera</p>
          <p class="tips-detail">
            Please ensure your camera is connected and on
          </p>
        </div>
      </div>
      <!-- cameraCode 4 -->
      <div class="cameraCode4" v-show="cameraCode == 4">
        <!-- local 视频 -->
        <div id="local" class="localStreamCont"></div>
        <!-- switch camera -->
        <div class="switchCamera">
          <p class="tips-title">CAN YOU SEE YOURSELF?</p>
          <p @click="selectCameraFun" class="tips-detail currentCamera">
            Current camera: <span>{{ selectCamera.value }}</span>
          </p>
        </div>
        <!-- buttom group -->
        <div class="buttonGroup font_three">
          <div class="customButton no" @click="stepCode_2_no()">NO</div>
          <div class="customButton yes" @click="stepCode_2_yes()">YES</div>
        </div>
      </div>
    </div>
    <!-- check 麦克风 -->
    <div class="contentStep stepCode_3" v-show="stepCode == 3">
      <!-- microphoneCode 3 -->
      <div class="microphoneCode3" v-show="microphoneCode == 3">
        <div class="content">
          <!-- img group -->
          <div class="imgGroup">
            <img
              :src="
                require('@/assets/img/08-Device-check/image-mic-access.svg')
              "
            />
          </div>
        </div>
        <!-- des -->
        <div v-if="nomicrophone">
          <p class="font_four fontWeight_medium">
            There was a problem setting up your microphone
          </p>
        </div>
        <div v-else>
          <p class="font_four fontWeight_medium">
            Allow the access to your mic
          </p>
          <p class="">Please ensure your micro is connected and on</p>
        </div>
      </div>
      <!-- microphoneCode 4 -->
      <div class="microphoneCode4" v-show="microphoneCode == 4">
        <!-- speak -->
        <div class="speak" v-show="!microphoneAudioUrl">
          <AnimateSpeak
            ref="animateSpeak"
            class="animateSpeak"
            @trumpMouseup="trumpMouseup"
          ></AnimateSpeak>
          <!-- des -->
          <p class="tips-title">SAY SOMETHING TO TEST YOUR MIC</p>
          <p class="tips-detail">
            Please speak into the microphone for > 5 seconds
          </p>
          <!-- buttom group -->
          <div class="buttonGroup">
            <div class="customButton no" @click="recordAgainFun()">
              RECORD AGAIN
            </div>
            <div class="customButton yes" @click="recordYes()">DONE</div>
          </div>
        </div>
        <!-- listen -->
        <div class="listen" v-show="microphoneAudioUrl">
          <!-- 音频动图 -->
          <AnimateAudioLine
            class="audioLine"
            ref="stepCode_3_audioLine"
            @click.native="stepCode_3_audioLineClick()"
          ></AnimateAudioLine>
          <!-- des -->
          <div>
            <p class="tips-title">RECORDING AUDIBLE?</p>
            <p class="tips-detail">Click play above to listen</p>
          </div>
          <!-- buttom group -->
          <div class="buttonGroup">
            <div class="customButton no" @click="stepCode_3_no()">NO</div>
            <div class="customButton yes" @click="stepCode_3_yes()">YES</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 结束状态 -->
    <div class="contentStep stepCode_4" v-show="stepCode == 4">
      <!-- all success -->
      <div class="allSuccess" v-show="allSuccess">
        <div class="sussess-content">
          <img
            class="icon checkmarkAnim"
            :src="require('@/assets/img/08-Device-check/image-success.svg')"
            style="margin: auto; margin-bottom: 20px"
          />
          <p class="tips-title" style="margin-bottom: 20px">SETUP COMPLETE</p>
          <p class="tips-detail">
            <span>Your system has been configured successfully.</span><br />
            <span>You can start the class now.</span>
          </p>
        </div>
        <!-- <div class="customButton" @click="startClass()">START CLASS</div> -->
      </div>
      <!-- some error -->
      <div class="someError" v-show="!allSuccess">
        <div class="error-content">
          <img
            class="icon checkmarkAnim"
            :src="require('@/assets/img/08-Device-check/image-error.svg')"
            style="margin: auto; margin-bottom: 20px"
          />
          <p class="tips-title" style="margin-bottom: 10px">Setup Failed</p>
          <p class="tips-detail">
            Sorry, the setup has failed since not all your devices are not
            working properly. Please make sure they are turned on and the access
            is allowed, and try again.
          </p>
        </div>
        <div class="errorGroup">
          <!-- audio problem -->
          <div class="errorRow" v-show="audioCode !== 1">
            <img
              :src="
                require('@/assets/img/08-Device-check/icon-error-small.svg')
              "
            />
            <p class="tips-device">Speaker</p>
          </div>
          <!-- camera problem -->
          <div class="errorRow" v-show="cameraCode !== 1">
            <img
              :src="
                require('@/assets/img/08-Device-check/icon-error-small.svg')
              "
            />
            <p class="tips-device">Camera</p>
          </div>
          <!-- microphone problem -->
          <div class="errorRow" v-show="microphoneCode !== 1">
            <img
              :src="
                require('@/assets/img/08-Device-check/icon-error-small.svg')
              "
            />
            <p class="tips-device">Microphone</p>
          </div>
        </div>
        <!-- <div class="customButton again" @click="reset()">TRY AGAIN</div> -->
      </div>
    </div>
    <!-- new select -->
    <NewSelect
      v-show="showSelect"
      ref="newSelect"
      :listData="cameraList"
      @selectClick="newSelectClick"
    ></NewSelect>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/tools";
import TRTC from "trtc-js-sdk";

let img_audio = require("@/assets/img/08-Device-check/icon-speaker-blue.svg");
let img_camera = require("@/assets/img/08-Device-check/icon-camera-blue.svg");
let img_microphone = require("@/assets/img/08-Device-check/icon-mic-blue.svg");
let img_check_success = require("@/assets/img/08-Device-check/icon-yellow-check.svg");
let img_check_error = require("@/assets/img/08-Device-check/icon-error-red.svg");

let localStream = null;
let recorder = null;
export default {
  components: {
    AnimateAudioLine: () => import("./AnimateAudioLine"),
    AnimateSpeak: () => import("./AnimateSpeak"),
    NewSelect: () => import("./NewSelect"),
  },
  computed: {
    allSuccess() {
      return (
        this.audioCode == 1 && this.cameraCode == 1 && this.microphoneCode == 1
      );
    },
  },
  data() {
    return {
      /**
       * 步骤状态
       * 0 初始
       * 1 扬声器
       * 2 摄像头
       * 3 麦克风
       * 4 结束
       * **/
      stepCode: 0,
      /**
       * 扬声器状态
       * 1 成功
       * 2 失败
       * **/
      audioCode: 0,
      /**
       * 摄像头状态
       * 1 成功
       * 2 失败
       * 3 未开始
       * 4 检测中
       * **/
      cameraCode: 3,
      nocamera: false,
      cameraList: [],
      selectCamera: {
        value: "",
        code: "",
      },
      /**
       * 麦克风状态
       * 1 成功
       * 2 失败
       * 3 未开始
       * 4 检测中
       * **/
      microphoneCode: 3,
      nomicrophone: false,
      microphoneList: [],
      microphoneAudioUrl: "",
      recordAgain: false,
      img_step1_title: img_audio,
      img_step2_title: img_camera,
      img_step3_title: img_microphone,
      // new select
      showSelect: false,
      showStep1: false,
      showStep2: false,
      showStep3: false,
      stepIsSemiTransparent1: false,
      stepIsSemiTransparent2: false,
      stepIsSemiTransparent3: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showStep1 = true;
    setTimeout(() => {
      this.showStep2 = true;
      this.stepIsSemiTransparent2 = true;

    setTimeout(() => {
      this.showStep3 = true;
      this.stepIsSemiTransparent3 = true;
    }, 200);

    }, 200);

    }, 200);
    
  },
  methods: {
    // 重置
    reset() {
      this.stepCode = 1;
      this.audioCode = 0;
      this.cameraCode = 3;
      this.nocamera = false;
      this.microphoneCode = 3;
      this.nomicrophone = false;
      this.img_step1_title = img_audio;
      this.img_step2_title = img_camera;
      this.img_step3_title = img_microphone;
    },
    // 关闭
    close() {
      this.$emit("close");
    },
    // 开始测试
    startCheck() {
      this.stepCode = 1;
      // 扬声器检测 音频自动播放
      let stepCode_1_audioLine = this.$refs.stepCode_1_audioLine;
      stepCode_1_audioLine.audioImgClick();
    },
    /** check 扬声器 start **/
    // 扬声器 no
    stepCode_1_no() {
      this.img_step1_title = img_check_error;
      this.audioCode = 2;
      this.stepCode = 2;
      this.cameraAllow();
      this.stepIsSemiTransparent2 = false;
    },
    // 扬声器 yes
    stepCode_1_yes() {
      this.img_step1_title = img_check_success;
      this.audioCode = 1;
      this.stepCode = 2;
      this.stepIsSemiTransparent2 = false;

      let stepCode_1_audioLine = this.$refs.stepCode_1_audioLine;
      stepCode_1_audioLine.load();
      this.cameraAllow();
    },
    /** check 扬声器 end **/

    /** check 摄像头 start **/
    // 摄像头装置检测
    cameraAllow() {
      TRTC.getCameras().then((result) => {
        // console.log(result)
        this.cameraList = deepCopy(result);
        console.log(this.cameraList);
        if (this.cameraList.length) {
          this.nocamera = false;
          this.cameraList.map((camera) => {
            camera.code = camera.deviceId;
            camera.value = camera.label;
          });
          let newSelect = this.$refs.newSelect;
          newSelect.selectClick(this.cameraList[0], 0);
        } else {
          this.nocamera = true;

          this.cameraNext(false);
        }
      });
    },
    // next
    cameraNext(flag) {
      this.stepIsSemiTransparent3 = false;
      if (flag) {
        this.cameraCode = 1;
        this.img_step2_title = img_check_success;
      } else {
        this.cameraCode = 2;
        this.img_step2_title = img_check_error;
      }

      this.stepCode = 3;
      this.microphoneAllow();
    },
    // 选择摄像头
    selectCameraFun(event) {
      this.showSelect = !this.showSelect;

      if (this.showSelect) {
        let select = this.$refs.newSelect.$el;

        let window_height = window.innerHeight;
        let window_width = window.innerWidth;
        let mouse_x = event.clientX;
        let mouse_y = event.clientY;

        select.style.top = `${mouse_y + 10}px`;
        select.style.left = `${mouse_x + -select.clientWidth / 2}px`;
      }
    },
    // 获取本地视频流
    getLocalStream() {
      let streamConfig = {
        audio: true,
        video: true,
      };
      if (this.selectCamera.deviceId) {
        streamConfig.cameraId = this.selectCamera.deviceId;
      }
      localStream = TRTC.createStream(streamConfig);
      localStream.initialize().then((res) => {
        localStream.play("local");
        this.cameraCode = 4;
      });
    },
    // 摄像头 no
    stepCode_2_no() {
      localStream.stop();
      // localStream = null;
      this.cameraNext(false);
    },
    // 摄像头 yes
    stepCode_2_yes() {
      localStream.stop();
      // localStream = null;
      this.cameraNext(true);
    },
    /** check 摄像头 end **/

    /** check 麦克风 start **/
    // 麦克风检测
    microphoneAllow() {
      TRTC.getMicrophones().then((result) => {
        // console.log(result)
        this.microphoneList = deepCopy(result);
        if (this.microphoneList.length) {
          this.nomicrophone = false;
          this.microphoneList.map((microphone) => {
            microphone.code = microphone.deviceId;
            microphone.value = microphone.label;
          });
          setTimeout(() => {
            this.microphoneCode = 4;
            let animateSpeak = this.$refs.animateSpeak;
            animateSpeak.trumpMousedown();
          }, 2000);
        } else {
          this.nomicrophone = true;
          this.img_step3_title = img_check_error;
          this.microphoneCode = 2;
          this.stepCode = 4;
        }
      });
    },
    // 授权
    // authorizationSuccess(){
    //   console.log('bbb')
    //   this.microphoneCode = 4;
    // },
    // next
    microphoneNext() {
      this.img_step3_title = img_check_error;
      this.stepCode = 4;
      this.microphoneCode = 2;
    },
    // 麦克风图标抬起
    trumpMouseup(data) {
      // console.log(data)
      if (this.recordAgain) {
        this.recordAgain = false;
        recorder = null;
      } else {
        recorder = data;
        this.microphoneAudioUrl = true;
        // this.microphoneAudioUrl = blob;
        // console.log(this.microphoneAudioUrl)
      }
    },
    // 重新录音
    recordAgainFun() {
      this.recordAgain = true;
      let animateSpeak = this.$refs.animateSpeak;
      animateSpeak.trumpMouseup();
      setTimeout(() => {
        animateSpeak.trumpMousedown();
      }, 500);
    },
    // 录音yes
    recordYes() {
      this.recordAgain = false;
      let animateSpeak = this.$refs.animateSpeak;
      animateSpeak.trumpMouseup();

      this.stepCode_3_audioLineClick();
      // let stepCode_3_audioLine = this.$refs.stepCode_3_audioLine;
      // stepCode_3_audioLine.audioImgClick();
    },
    // 播放点击
    stepCode_3_audioLineClick() {
      console.log(recorder);
      if (recorder) {
        let time = recorder.duration;
        // console.log(time)
        let stepCode_3_audioLine = this.$refs.stepCode_3_audioLine;
        stepCode_3_audioLine.startGIF();
        recorder.play();
        setTimeout(() => {
          stepCode_3_audioLine.endGIF();
        }, time * 1000);
      }
    },
    // no
    stepCode_3_no() {
      this.img_step3_title = img_check_error;
      this.microphoneCode = 2;
      this.stepCode = 4;

      let stepCode_3_audioLine = this.$refs.stepCode_3_audioLine;
      stepCode_3_audioLine.load();
    },
    // yes
    stepCode_3_yes() {
      this.img_step3_title = img_check_success;
      this.microphoneCode = 1;
      this.stepCode = 4;

      let stepCode_3_audioLine = this.$refs.stepCode_3_audioLine;
      stepCode_3_audioLine.load();
    },
    /** check 麦克风 end **/

    /** step 4  start **/
    startClass() {
      localStream.close();
      // localStream = null;
      this.$emit("startClass");
    },
    /** step 4 end **/
    // new select
    newSelectClick(data, index) {
      // 当前状态 摄像头
      if (this.stepCode == 2) {
        this.selectCamera = deepCopy(data);
        console.log(data);
        localStorage.selectCamera = JSON.stringify(data);
        this.getLocalStream();
      }
      this.showSelect = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .computer img {
  width: 100%;
}

.stepIsSemiTransparent {
  opacity: 0.5;
  transition: all 0.5s;
        box-shadow: 0 0 10px -10px #2472b3;
}
.device-check-container {
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 550px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  .optionTitle {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .optionClose {
      width: 35px;
      height: 35px;
      // transform: rotate(45deg);
      cursor: pointer;
    }
  }
  .switchStep {
    display: flex;
    width: 240px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    .line {
      height: 3px;
      width: 100%;
      position: absolute;
      background-color: #2472b3;
      z-index: 1;
      left: 0;
      top: 50%;
      transition: all 1s;

    }
    .stepIcon {
      width: 70px;
      height: 70px;
      z-index: 2;
      &.status {
        width: 40px;
        height: 40px;
      }
      &.noStatus {
        border: 5px solid #f4d737;
        border-radius: 50%;
      }
    }
  }
  .contentStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex: 1;
    .start {
      color: #fff;
      background: #224e96;
        transition: all 0.5s;
      // text-align: center;
        &:hover {
              transform: translateY(-3px);
        }
    }
    .again {
      color: #fff;
      background: #224e96;
        transition: all 0.5s;
        &:hover {
              transform: translateY(-3px);
        }
    }
    .buttonGroup {
      display: flex;
      .customButton {
        min-width: 40px;
        margin: 0 10px;
        transition: all 0.5s;
        &:hover {
              transform: translateY(-3px);
        }
      }

      .no {
        color: #000;
        background: #f1f1f0;
        text-align: center;
      }
      .yes {
        color: #000;
        background: #f4d737;
        text-align: center;
      }
    }
    p {
      text-align: center;
    }
  }

  .stepCode_0 {
    .computer {
      width: 80%;
      //   height: 100%;
    }
  }
  .stepCode_1 {
    .audioLine {
      width: 214px;
      height: 214px;
      margin-bottom: 20px;
      cursor: default;
    }
    .des {
      margin-bottom: 15px;
    }
  }
  .stepCode_2 {
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    .cameraCode3 {
      .content {
        width: 280px;
        box-sizing: border-box;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        .imgGroup {
          margin-bottom: 10px;
          img {
            width: 100%;
          }
        }
        .line {
          height: 7px;
          border-radius: 10px;
          background-color: #2472b3;
          margin-bottom: 5px;
          &.one {
            width: 150px;
          }
          &.two {
            width: 77px;
          }
        }
        .buttonGroup {
          display: flex;
          justify-content: flex-end;
          .next {
            margin-left: 10px;
          }
        }
      }
    }
    .cameraCode4 {
      .localStreamCont {
        width: 215px;
        height: 215px;
        background-color: #2472b3;
        border-radius: 50%;
        overflow: hidden;
      }
      .switchCamera {
        .currentCamera {
          cursor: pointer;
        }
      }
    }
  }
  .stepCode_3 {
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    .microphoneCode3 {
      .content {
        width: 280px;
        box-sizing: border-box;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        .imgGroup {
          margin-bottom: 10px;
          img {
            width: 100%;
          }
        }
        .line {
          height: 7px;
          border-radius: 10px;
          background-color: #2472b3;
          margin-bottom: 5px;
          &.one {
            width: 150px;
          }
          &.two {
            width: 77px;
          }
        }
        .buttonGroup {
          display: flex;
          justify-content: flex-end;
          .next {
            margin-left: 10px;
          }
        }
      }
    }
    .microphoneCode4 {
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
      }
      .speak {
        .animateSpeak {
          width: 214px;
          height: 214px;
          // background-color: #2472b3;
        }
      }
      .listen {
        .audioLine {
          width: 214px;
          height: 214px;
        }
      }
    }
  }
  .stepCode_4 {
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    justify-content: flex-start;
    // .icon {
    //   width: 80px;
    //   height: 80px;
    // }
    .allSuccess {
      margin-top: 15%;
      .sussess-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .someError {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .error-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        .errorGroup {
          display: flex;
          flex-direction: column;
          align-items: center;
          .errorRow {
            display: flex;
            align-items: center;
            margin: 5px 0;
            .new_img {
              width: 23px;
              height: 23px;
              transform: rotate(45deg);
              margin-right: 10px;
            }
          }
        }
      }
      .errorGroup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
        .errorRow {
          display: flex;
          align-items: center;
          margin: 8px 0;
          .new_img {
            width: 23px;
            height: 23px;
            transform: rotate(45deg);
            margin-right: 10px;
          }
        }
      }
    }
  }
  .tips-title {
    font-size: 16px;
  }
  .tips-detail {
    font-size: 12px;
  }
  .tips-device {
    font-size: 14px;
    margin-left: 10px;
  }
}

.checkmarkAnim {
    animation: checkmarkAnim 1s linear;
    transform-origin: center;
}
/* Heart beat animation */
@keyframes checkmarkAnim{
    from { opacity:0;transform: scale(2.1); }
    to { opacity:1;transform: scale(1); }
}
</style>