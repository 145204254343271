<template>
  <div class="dashboard-container">
    <Layout>
      <template #left>
        <div class="aside">
          <SiteAside />
        </div>
      </template>
      <template #main>
        <div class="main">
          <div class="header">
            <Header />
          </div>
          <div class="content" ref="contentBox">
            <!-- <keep-alive> -->
            <RouterView />
            <!-- </keep-alive> -->
          </div>
          <div class="foot-logo">
            <img src="@/assets/img/05-Panda/panda-help.svg" alt="" />
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
export default {
  name: "dashboard",
};
</script>

<script>
// import { myBrowser } from "@/common/js/rejular";
import Layout from "@/components/Page/Layout";
import SiteAside from "@/components/Page/SiteAside";
import Header from "@/components/Page/Header";

export default {
  name: "Dashboard",
  data() {
    return {};
  },
  methods: {
    // 浏览器判断
    myBrowser() {
      let browser = myBrowser();
      if (browser !== "Chrome") {
        // let message = 'Your current browser cannot run our full functionality, We recommend using Chrome browser!'
        // this.$message({
        //   type: 'warning',
        //   message: message
        // })
      }
    },
  },
  components: {
    Layout,
    SiteAside,
    Header,
  },
};
</script>


<style lang="scss" scoped>
// .fontpreloading {
//   // display: none;
//   position: fixed;
//   top: -10000px;
// }
.dashboard-container {
  background: #fafafa;
  font-weight: 400;
  letter-spacing: 0.5px;

  .aside,
  .main {
    // width: 100%;
    height: 100%;
  }
  .main {
    width: 90%;
    margin-left: 7%;
    margin-right: 3%;
    position: relative;
    .header {
      margin-top: 1%;
    }
    .content {
      margin-right: 6%;
      // height: calc(99% - 36px);
      // overflow-y: auto;
    }

    .foot-logo {
      position: absolute;
      bottom: 1%;
      right: 0%;
      z-index: 10;
      img {
        width: 100%;
        max-width: 50px;
      }
    }
  }
}
</style>
