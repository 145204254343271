var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("div", { staticClass: "header-bar font-menu-small" }, [
      _c("div", { staticClass: "course-pakeges" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.goToPackagesPage()
              },
            },
          },
          [_vm._v("Course Packages")]
        ),
      ]),
      _c("div", { staticClass: "area-time" }, [
        _c("span", [_vm._v(_vm._s(_vm.getTime))]),
      ]),
      _c(
        "div",
        { staticClass: "profile" },
        [
          _c("div", { staticClass: "profile-avatar" }, [
            _c("img", { attrs: { src: _vm.currentUser.photo, alt: "" } }),
          ]),
          _c(
            "el-dropdown",
            { on: { command: _vm.handleSettingChange } },
            [
              _c("div", { staticClass: "el-dropdown-link" }, [
                _c("span", { staticClass: "profile-name" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentUser.firstName +
                          " " +
                          _vm.currentUser.lastName
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "setting-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.profileList, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    { key: index, attrs: { command: item.id } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }